<template>
	<AtomsModal @close="closePopup" class="pay-modal">
		
<FormKitLazyProvider config-file="true" :default-config="false">
<FormKit form-class="pay-modal-form" @submit="onSubmit" type="form" :actions="false" :incomplete-message="false" #="{ state, value }">
			<h2 class="pay-modal-title">{{ currentProduct?.name }}</h2>
			<FormKit type="text" name="account" validation="required:trim" :placeholder="$t('steam.place_account')" :label="$t('steam.account')" />
			<FormKit type="number" placeholder="0.00" :validation="`required|min:${minimumDepositAmount}`" name="amount" :label="$t('steam.amount')">
				<template #suffix>
					<div class="price-addition-text-box">
						<p class="price-addition-text">
							{{ $t('payments.min_payment') }}
							<span>{{ getCurrentCurrencyString(currentProduct?.type.payment?.minimum_deposit_amount ?? 0) }}</span>
						</p>
						<p class="price-addition-text">
							{{ $t('payments.commission') }}
							<span>{{ currentProduct?.type.payment?.commission }}%</span>
						</p>
					</div>
				</template>
			</FormKit>
			<FormKit type="email" v-if="!user.user" name="email" validation="required:trim | email" :placeholder="$t('steam.place_email')" :label="$t('steam.email')" />
			<div class="dispute-plashka">
				<img src="~/assets/images/svg/clock.svg" width="32px" height="32px" alt="">
				<p>{{ $t('product.pay_modal.dispute_time') }}</p>
			</div>
			<div class="checkbox-container" v-if="!isLoggedIn">
				<FormKit name="checkbox_1" :value="true" type="checkbox">
					<template #label="{ id, classes }">
						<label :for="id" :class="classes.label">{{ $t('interface.i_agree') }} <a :href="`/${locale}/terms-of-use.pdf`" target="_blank">{{ $t('forms.checkbox.terms_of_use') }}</a></label>
					</template>
				</FormKit>
				<FormKit name="checkbox_2" :value="true" type="checkbox">
					<template #label="{ id, classes }">
						<label :for="id" :class="classes.label">{{ $t('interface.i_agree') }} <a :href="`/${locale}/privacy-cookie-policy.pdf`" target="_blank">{{ $t('forms.checkbox.privacy_cookie_policy') }}</a></label>
					</template>
				</FormKit>
			</div>
			<button :class="{ 'btn-loaded': state.loading }" :disabled="!value?.checkbox_1 && !isLoggedIn || !value?.checkbox_2 && !isLoggedIn || state.loading" class="btn btn-secondary" type="submit">{{ $t('steam.top_up') }}</button>
		</FormKit>
</FormKitLazyProvider>

	</AtomsModal>
</template>

<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import { usePayments } from '~/composables/payment/usePayments';
import { useUserAuth } from '~/composables/user/UseUserAuth';
import { detectDeviceAndRedirect } from '~/assets/functions';
const { locale, t } = useI18n()
const { topUp } = usePayments()
const { closePopup, currentProduct } = useSteamPayModal()
const { user, isLoggedIn } = storeToRefs(useUserStore())
const { getUserMe } = useUserAuth()
const { $toast } = useNuxtApp();
const minimumDepositAmount = computed(() => getCurrentCurrencyAmount(currentProduct.value?.type.payment?.minimum_deposit_amount ?? 0))
const { getCurrentCurrencyAmount, getCurrentCurrencyString, getUsdAmount } = useCurrencyHelpers()



const onSubmit = async (options: { amount: number, email?: string, account: string }) => {
	if (!currentProduct.value) return

	const topUpAmount = Math.round(Number(options.amount) * (1 + (Number(currentProduct.value.type.payment?.commission) / 100)) * 100) / 100

	await topUp({
		account: options.account,
		amount: getUsdAmount(topUpAmount),
		email: options.email ?? user.value.user.email,
		topUpAmount: getUsdAmount(options.amount)
	}).then(res => {
		$toast.success(t('forms.success'))
		detectDeviceAndRedirect(res?.paymentUrl)
		const authToken = useCookie('authToken', {
			maxAge: (60 * 60 * 24 * 30 * 12),
			path: '/'
		});
		authToken.value = res?.token
		setTimeout(() => {
			getUserMe();
		}, 1000)
	}).catch(err => {
		let message = null
		switch (err.statusCode) {
			case 400:
				message = t('steam.error_400')
				break;
			case 403:
				message = t('steam.error_403')
				break;
			case 404:
				message = t('steam.error_404')
				break;
			case 500:
				message = t('steam.error_500')
				break;
			default:
				message = t('forms.error')
		}
		$toast.error(message)
	})
}
</script>

<style lang="scss" scoped>
.pay-modal {
	:deep(.modal-content) {
		max-width: 662px;
	}

	.btn {
		font-weight: 700;
		font-size: 18px;
		line-height: 80%;
		letter-spacing: -0.03em;
		padding: 12px 40px;
		width: 100%;
		max-width: 254px;
		margin-top: 4px;
	}
}

.price-addition-text-box {
	display: flex;
	gap: 14.5px;
	font-weight: 600;
	font-size: 12px;
	line-height: 80%;
	letter-spacing: -0.03em;
	text-align: center;
	color: rgba(29, 37, 74, 0.6);
	text-align: left;
	position: absolute;
	right: 12px;
	top: 0;
	height: 100%;
	align-items: center;

	.price-addition-text {
		display: flex;
		flex-direction: column;
		gap: 4px;
	}

	span {
		font-weight: 800;
		color: #777c93;
	}
}

.pay-modal-form {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.pay-modal-title {
	font-weight: 800;
	font-size: 28px;
	line-height: 80%;
	letter-spacing: -0.03em;
	color: #1d254a;
	margin-bottom: 12px;
}

.dispute-plashka {
	display: flex;
	gap: 12px;
	align-items: center;
	border: 1px solid rgba(218, 71, 39, 0.2);
	border-radius: 12px;
	box-shadow: 0 4px 6px 0 rgba(77, 24, 13, 0.1);
	background: rgba(218, 71, 39, 0.15);
	padding: 12px 16px;
	font-weight: 600;
	font-size: 18px;
	line-height: 80%;
	letter-spacing: -0.03em;
	color: #da4727;
	margin-top: 18px;

	img {
		width: 32px;
		height: 32px;
	}
}

.checkbox-container {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

@media (max-width:1023px) {
	.pay-modal-title {
		font-size: 24px;
		margin-top: 0;
		margin-bottom: 0;
	}

	.dispute-plashka {
		gap: 8px;
		border-radius: 12px;
		padding: 10px;
		font-size: 16px;
		margin-top: -4px;

		img {
			width: 28px;
			height: 28px;
		}
	}

	.btn {
		flex: 1;
		padding: 11px 22px 9px;
		font-size: 16px;
	}

	.checkbox-container {
		margin-top: -8px;
	}
}
</style>